import React, { useState, useMemo, useCallback } from 'react';
import styles from './ListViewPage.module.sass';
import PinRed from './pin_red.png';
import PinGrey from './pin_grey.png';
import BookmarkGrey from './bookmark_grey.png';
import BookmarkGreen from './bookmark_green.png';
import { Link } from 'react-router-dom';
import { RedButton } from '../../commonComponents/RedButton/RedButton';
import { useSessionFolder } from '../../../hooks/useSessionFolder';
import { Empty } from 'antd';
import { BorderedButton } from '../../commonComponents/BorderedButton/BorderedButton';
import { CreateSession } from '../Create';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteSession } from '../Delete';
import { useVideoCategoriesByAction } from '../../../hooks/useVideoCategoriesByAction';
import useUserTeams from '../../../hooks/useUserTeam';
import {
  createFavoriteJourney,
  deleteFavoriteBySubTitle,
  createHomework,
  deleteHomework,
} from '../../../api/api';
import {
  addHomework,
  deleteFavorite,
  addFavorite,
  removeHomework,
} from '../../../Redux/listViewReducer';
import { ModalAssignment } from '../../ProfilePage/ModalWindowPopap/ModalAssignment';

const List = ({ data, view }) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const { playerData } = useSelector((state) => state.playerDataReducer);
  const favoriteData = useVideoCategoriesByAction('SET_MY_FAVORITE_CATEGORY_DATA').find(
    (item) => item.actionType === 'SET_MY_FAVORITE_SUBCATEGORY_DATA',
  ).children;

  const { manageTeams } = useUserTeams();

  const { data: listData } = useSelector((state) => state.listViewReducer);

  const homeworkData = useMemo(() => {
    const allHomework = listData
      ?.find((item) => item.actionType === 'SET_MY_HOMEWORK_DATA')
      .children.map((item) => item.children[0].children)
      .flat();

    // Use reduce to filter out duplicate entries based on title and subTitle
    const uniqueHomework = allHomework.reduce((acc, current) => {
      const duplicate = acc.find(
        (item) => item?.title === current?.title && item?.subTitle === current?.subTitle,
      );
      if (!duplicate) {
        return [...acc, current];
      }
      return acc;
    }, []);

    return uniqueHomework;
  }, [listData]);

  const dispatch = useDispatch();

  const checkFavorite = useCallback(
    (_item) => {
      const favoriteCheck = favoriteData.some((item) => {
        if (item?.subTitle !== '') {
          return item.subTitle === _item.subTitle;
        } else {
          return item.title === _item.title;
        }
      });

      return favoriteCheck;
    },
    [favoriteData],
  );

  const checkHomeworkWithFavorite = useCallback(
    (_item) => {
      const homeworkCheck = homeworkData.some((item) => {
        if (item?.subTitle !== '') {
          return item.subTitle === _item.subTitle;
        } else {
          return item.title === _item.title;
        }
      });

      const favoriteCheck = favoriteData.some((item) => {
        if (item?.subTitle !== '') {
          return item.subTitle === _item.subTitle;
        } else {
          return item.title === _item.title;
        }
      });

      return manageTeams.length > 0 ? homeworkCheck || favoriteCheck : favoriteCheck;
    },
    [favoriteData, homeworkData, manageTeams],
  );

  const checkHomeworkWithTeam = useCallback(
    (team, _item) => {
      const teamData = listData
        ?.find((item) => item.actionType === 'SET_MY_HOMEWORK_DATA')
        ?.children.find((item) => item.actionType === `cat-${team.id}`)?.children[0].children;

      const homeworkCheck = teamData?.some((item) => {
        if (item?.subTitle !== '') {
          return item.subTitle === _item.subTitle;
        } else {
          return item.title === _item.title;
        }
      });

      return homeworkCheck;
    },
    [data],
  );

  const {
    handleCloseFolder,
    handlePinnedFolder,
    data: myFolder,
    handleBookmarkFolder,
    handleUpdateFolder,
  } = useSessionFolder();
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [deleted, setDeleted] = useState(null);

  const handlePinned = (id) => {
    handlePinnedFolder(id);
  };

  const checkFolder = (id) => {
    return myFolder.some((f) => f.id === id);
  };

  const toggleBookmarkFolder = (id) => {
    handleBookmarkFolder(id);
  };

  const showDeleteConfirm = (id) => {
    handleCloseFolder(id, {
      onSettled: () => setDeleted(null),
    });
  };

  const handleFavoriteClick = useCallback(
    async (item) => {
      if (manageTeams.length > 0) {
        setSelectedItem(item);
        return;
      }

      if (manageTeams.length === 0) {
        if (checkFavorite(item)) {
          await deleteFavoriteBySubTitle(item).then((res) => {
            console.log(res.msg);
            dispatch(deleteFavorite(item));
          });
        } else {
          await createFavoriteJourney(item).then((res) => {
            dispatch(addFavorite(res.favorite));
          });
        }
      }
    },
    [checkFavorite, manageTeams, dispatch],
  );

  const onSubmit = useCallback(
    async (teams, addToFavorite) => {
      // Remove homework from teams that have been unchecked
      const teamsToRemoveHomework = manageTeams.filter(
        (team) =>
          checkHomeworkWithTeam(team, selectedItem) &&
          !teams.some((selectedTeam) => selectedTeam.id === team.id),
      );

      for (const team of teamsToRemoveHomework) {
        await deleteHomework({ ...selectedItem, team }).then((res) => {
          console.info(res.msg);
          dispatch(removeHomework(selectedItem, team));
        });
      }

      // Add homework to newly selected teams
      for (const team of teams) {
        if (!checkHomeworkWithTeam(team, selectedItem)) {
          const newSelectedItem = {
            ...selectedItem,
            teamId: team.id,
          };

          if (!selectedItem.actionType) {
            newSelectedItem.folderId = selectedItem.id;
          }

          await createHomework(newSelectedItem).then((res) => {
            console.info(res.success);
            dispatch(addHomework(newSelectedItem, team));
          });
        }
      }

      // Handle favorites
      if (checkFavorite(selectedItem) === false && addToFavorite === true) {
        const newSelectedItem = {
          ...selectedItem,
        };

        if (!selectedItem.actionType) {
          newSelectedItem.folderId = selectedItem.id;
        }

        await createFavoriteJourney(newSelectedItem).then((res) => {
          dispatch(addFavorite(res.favorite));
        });
      }

      if (checkFavorite(selectedItem) === true && addToFavorite === false) {
        await deleteFavoriteBySubTitle(selectedItem).then((res) => {
          console.log(res.msg);
          dispatch(deleteFavorite(selectedItem));
        });
      }

      setSelectedItem(null);
    },
    [checkFavorite, checkHomeworkWithTeam, selectedItem, dispatch, manageTeams],
  );

  return (
    <div className={styles.videoCluster}>
      {data?.length > 0 ? (
        <ul>
          {data.map((folder) => {
            const isPlayerData = folder.childId === playerData.id;
            return (
              <li key={folder.id} className={styles.listRow}>
                <div className={styles.listRow}>
                  <div className={styles.listItem}>
                    {isPlayerData ? (
                      <>
                        {view === 'my-session' && (
                          <img
                            className={styles.pin}
                            src={
                              checkHomeworkWithFavorite(folder) || checkFavorite(folder)
                                ? PinRed
                                : PinGrey
                            }
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleFavoriteClick(folder)}
                          />
                        )}
                      </>
                    ) : (
                      <img
                        className={styles.pin}
                        src={checkFolder(folder.id) ? BookmarkGreen : BookmarkGrey}
                        style={{ cursor: 'pointer' }}
                        onClick={() => toggleBookmarkFolder(folder.id)}
                      />
                    )}
                    {view === 'find-session' && (
                      <img
                        className={styles.pin}
                        src={
                          checkHomeworkWithFavorite(folder) || checkFavorite(folder)
                            ? PinRed
                            : PinGrey
                        }
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleFavoriteClick(folder)}
                      />
                    )}
                    <Link
                      to={{
                        pathname: `my-training-sessions/${folder.id}`,
                      }}
                      className={styles.textListItem}
                    >
                      {folder.title}
                    </Link>
                  </div>
                  <div className={styles.bookmarkColumn}>
                    {/* {!categoriesRegex.test(selectedSubCategory) && (
                          <div onClick={() => handleHideFolderClick(child)} style={{
                            cursor: 'pointer',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}>
                            {checkHiddenFolder(child) ? (
                              <EyeFilled style={{
                                fontSize: '20px',
                                color: '#e1343f'
                              }} />
                            ) : (
                              <EyeInvisibleFilled style={{
                                fontSize: '20px',
                                color: '#86adc5'
                              }} />
                            )}
                          </div>
                      )} */}
                    {/* <img
                          className={styles.bookmark}
                          src={checkBookmark(child.subTitle) ? BookmarkGreen : BookmarkGrey}
                          data-subtitle={child.subTitle}
                          onClick={(e) => switchBookmark(e)}
                        /> */}
                    {isPlayerData && (
                      <>
                        {view === 'my-session' && (
                          <>
                            <BorderedButton
                              title="Edit Name"
                              className={styles.button}
                              handleButtonClick={() => setSelectedFolder(folder)}
                            ></BorderedButton>
                            <RedButton
                              handleButtonClick={() => setDeleted(folder)}
                              className={styles.button}
                            >
                              Delete
                            </RedButton>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      ) : (
        <Empty description="No Training Session" />
      )}
      {!!selectedFolder && (
        <CreateSession
          folder={selectedFolder}
          handleSubmit={handleUpdateFolder}
          onClose={() => setSelectedFolder(null)}
          edit
        />
      )}
      {!!deleted && (
        <DeleteSession
          data={deleted}
          handleSubmit={showDeleteConfirm}
          onClose={() => setDeleted(null)}
        />
      )}

      {selectedItem && (
        <ModalAssignment
          manageTeams={manageTeams}
          onClose={() => setSelectedItem(null)}
          initFav={checkFavorite(selectedItem)}
          onSubmit={onSubmit}
          selectedItem={selectedItem}
        />
      )}
    </div>
  );
};

export default List;
